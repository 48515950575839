
import { Vue } from 'vue-class-component'
import axios from '@/plugins/axios'

interface Result {
  message: string
  status: boolean
  data: any
}

export default class BraintreeAPI extends Vue {
  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account')
  }
  dataForm = {
    merchantId: '',
    privateKey: '',
    publicKey: '',
    environment: 'Sandbox'
  }
  listEnvironment = [
    {
      label: 'Sandbox',
      value: 'Sandbox'
    },
    {
      label: 'Development',
      value: 'Development'
    },
    {
      label: 'Production',
      value: 'Production'
    },
    {
      label: 'Qa',
      value: 'Qa'
    }
  ]

  async created() {
    await this.getData()
  }

  async getData() {
    await this.$store.dispatch('setLoading', true, { root: true })

    let res: Result
    res = await axios.get('admin/braintree-api', {
      headers: this.headers
    })

    if (res) {
      this.dataForm = res.data
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  async handleSave() {
    const refFrorm = this?.$refs['refForm'] as HTMLFormElement
    const isValidate = await refFrorm.validate()
    if (!isValidate) return

    let res: Result
    await this.$store.dispatch('setLoading', true, { root: true })

    res = await axios.put('admin/braintree-api', this.dataForm, {
      headers: this.headers
    })

    if (res.status) {
      this.$message.success(res.message)
      await this.getData()
    } else {
      this.$message.warning(res.message)
      await this.getData()
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }
}
